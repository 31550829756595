<template>
  <div class="card p-shadow-6">
    <W4Mstreak
      :states="streakStatus"
      v-on:W4MStreakChangeSelected="streakSelectedChange"
    />
    <div style="height: 65vh">
      <DataTable
        :value="selectedProposals"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="streakIndex"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        removableSort
        groupRowsBy="status"
        :expandableRowGroups="true"
        sortMode="single"
        :scrollable="true"
        :expandedRowGroups.sync="expandedRowGroups"
        scrollHeight="flex"
      >
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #loading> A carregar dados. Por favor Aguarde... </template>
        <template #groupheader>
          <div class="myGroupHeader" :style="getGroupheaderColor()">
            {{ currentSelect }}
          </div>
        </template>
        <Column
          sortable
          field="id"
          header="Id"
          filterField="id"
          :headerStyle="{ 'min-width': '9%', 'max-width': '9%' }"
          :filterHeaderStyle="{ 'min-width': '9%', 'max-width': '9%' }"
          :bodyStyle="{ 'min-width': '9%', 'max-width': '9%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Ver Proposta'"
              @click="viewProposal(slotProps.data.id)"
              >{{ slotProps.data.id }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="client_name"
          header="Cliente"
          filterField="client_name"
          :headerStyle="{ 'min-width': '14%', 'max-width': '14%' }"
          :filterHeaderStyle="{ 'min-width': '14%', 'max-width': '14%' }"
          :bodyStyle="{ 'min-width': '14%', 'max-width': '14%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.client_name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="subject"
          header="Assunto"
          filterField="subject"
          :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
          :filterHeaderStyle="{ 'min-width': '25%', 'max-width': '25%' }"
          :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.subject }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          header="Estado"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            <i
              class="pi pi-bookmark-fill streak-icon"
              :style="getIconColor()"
            ></i>
            {{ slotProps.data.status }}
          </template>
        </Column>
        <Column
          sortable
          field="type.name"
          header="Tipo"
          filterField="type.name"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="seller.name"
          header="Vendedor"
          filterField="seller.name"
          :headerStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :filterHeaderStyle="{ 'min-width': '8%', 'max-width': '8%' }"
          :bodyStyle="{ 'min-width': '8%', 'max-width': '8%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.seller_id ? slotProps.data.seller.name : null }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="proposal_assigned_user.username"
          header="Responável"
          filterField="proposal_assigned_user.username"
          :headerStyle="{ 'min-width': '9%', 'max-width': '9%' }"
          :filterHeaderStyle="{ 'min-width': '9%', 'max-width': '9%' }"
          :bodyStyle="{ 'min-width': '9%', 'max-width': '9%' }"
        >
          <template #body="slotProps">
            {{
              slotProps.data.assigned_to
                ? slotProps.data.proposal_assigned_user.username
                : null
            }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="created_at"
          header="Criado"
          filterField="created_at"
          dataType="date"
          :headerStyle="{ 'min-width': '9.5%', 'max-width': '9.5%' }"
          :filterHeaderStyle="{ 'min-width': '9.5%', 'max-width': '9.5%' }"
          :bodyStyle="{ 'min-width': '9.5%', 'max-width': '9.5%' }"
        >
          <template #body="slotProps">
            <span>{{ getDateTime(slotProps.data.created_at) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          sortable
          field="updated_at"
          header="Atualizado"
          filterField="updated_at"
          dataType="date"
          :headerStyle="{ 'min-width': '9.5%', 'max-width': '9.5%' }"
          :filterHeaderStyle="{ 'min-width': '9.5%', 'max-width': '9.5%' }"
          :bodyStyle="{ 'min-width': '9.5%', 'max-width': '9.5%' }"
        >
          <template #body="slotProps">
            <span>{{ getDateTime(slotProps.data.updated_at) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import userService from "../services/user.service";
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate, getOnlyTime } from "../helpers/helpers";
export default {
  name: "Home",
  components: {
    W4Mstreak,
  },
  data() {
    return {
      proposals: [],
      selectedProposals: [],
      streakStatus: [],
      expandedRowGroups: [],
      loading: false,
      currentSelect: null,
      filters: {
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        priority: { value: null, matchMode: FilterMatchMode.EQUALS },
        category: { value: null, matchMode: FilterMatchMode.EQUALS },
        "type.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "seller.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "proposal_assigned_user.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
        client_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        created_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
        updated_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user;
      }
      return null;
    },
  },
  async beforeCreate() {},
  async created() {
    this.loading = true;
    this.proposals = await userService.getProposals();
    this.initStreak();
    this.loading = false;
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
  methods: {
    initStreak() {
      this.streakStatus = [
        {
          backgroudColor: "#FFA100",
          textColor: "#000",
          title: "Por Marcar",
          counter: this.proposals["Por Marcar"].length,
          active: false,
        },
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Tentativa Marcação",
          counter: this.proposals["Tentativa Marcação"].length,
          active: false,
        },
        {
          backgroudColor: "#E1C800",
          textColor: "#000",
          title: "Por Marcar (Contactar Gestor)",
          counter: this.proposals["Por Marcar (Contactar Gestor)"].length,
          active: false,
        },
        {
          backgroudColor: "#8E9BEF",
          textColor: "#FFF",
          title: "Marcado",
          counter: this.proposals["Marcado"].length,
          active: false,
        },
        {
          backgroudColor: "#5F78DA",
          textColor: "#FFF",
          title: "Pendente",
          counter: this.proposals["Pendente"].length,
          active: false,
        },
        {
          backgroudColor: "#4455BD",
          textColor: "#FFF",
          title: "Por Realizar",
          counter: this.proposals["Por Realizar"].length,
          active: false,
        },
        {
          backgroudColor: "#191D80",
          textColor: "#FFF",
          title: "Sem Relatório",
          counter: this.proposals["Sem Relatório"].length,
          active: false,
        },
        {
          backgroudColor: "#005B94",
          textColor: "#FFF",
          title: "Sem Orçamento",
          counter: this.proposals["Sem Orçamento"].length,
          active: false,
        },
        {
          backgroudColor: "#00b6bc",
          textColor: "#FFF",
          title: "Com Orçamento",
          counter: this.proposals["Com Orçamento"].length,
          active: false,
        },
      ];
    },
    streakSelectedChange(streakInfo) {
      this.loading = true;
      this.currentSelect = streakInfo.currentState;
      this.selectedProposals = this.proposals[streakInfo.currentState].map(
        (v) => ({
          ...v,
          status: streakInfo.currentState,
          created_at: new Date(`${v.created_at}`),
          updated_at: new Date(`${v.updated_at}`),
        })
      );
      this.loading = false;
      return this.expandedRowGroups.push(streakInfo.currentState);
    },
    getDateTime(date) {
      return `${getOnlyDate(date)} ${getOnlyTime(date)}`;
    },
    getGroupheaderColor() {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == this.currentSelect
      );
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: statusInfo.backgroudColor,
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    getIconColor() {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == this.currentSelect
      );
      return { color: statusInfo.backgroudColor };
    },
    viewProposal(proposalId) {
      let route = this.$router.resolve({
        path: `/proposal-detail/${proposalId}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
<style>
.p-column-filter {
  width: 100%;
}

.p-tooltip {
  z-index: 5;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 5;
}

.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important;
}

.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.streak-icon {
  transform: rotate(90deg);
}
</style>
